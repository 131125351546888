import * as React from "react"
import Layout from "../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import
Form,
{
    FormSection,
    InputField as StandardInputField,
    Columns,
    FormButton,
    FormStateConsumer,
    Message,
    FieldRequired,
    ReactSelectField,
    TextArea as StandardTextArea,
    PostSuccessActions
}
    from "../components/form/form"
import useOMD from "../components/hooks/useOMD"

import merge from 'lodash.merge'
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import Seo from "../components/seo"

const cleanUpFormParamsBeforeSend = (params) => {

    const newParams = {}

    for (const key in params) {
        const splitKey = key.split('.')

        if (splitKey && splitKey.length > 0) {
            const objectFromKey = splitKey
                .reduce(
                    (acc, current, index, array) => {

                        if (!acc.fullObject) {
                            // First loop, we bind the full Object to current Object reference
                            acc.fullObject = acc.currentObject
                        }

                        if (index + 1 === array.length) {
                            // Last loop, current Object's value is the real value
                            const realValue =
                                Array.isArray(params[key].value)
                                    ?
                                    params[key].value.map(value => (value.value !== undefined) ? value.value : value)
                                    :
                                    (params[key].value && (params[key].value.value !== undefined)) ? params[key].value.value : params[key].value
                            acc.currentObject[current] = realValue
                        }
                        else {
                            // Not last loop, current Object's value will be a nested object
                            acc.currentObject[current] = {}
                            acc.currentObject = acc.currentObject[current]
                        }

                        return acc
                    }
                    , { currentObject: {}, fullObject: null }
                )
            merge(newParams, objectFromKey.fullObject)
        }
        else {
            console.error("Problem splitting key : ", key, "result is :", splitKey)
        }
    }

    // Move all keys beneath membrane key one level up
    merge(newParams, newParams.membrane)
    newParams.membrane = null
    delete newParams.membrane

    // Check if data.cpMode is absent, data.cpMode is required by the backend, we send it as not available
    !newParams.data.cpMode && (newParams.data.cpMode = 'Not available')

    // We put some more (meta)data into the comment part of the submission
    newParams.comment =
        "WEBSITE SUBMISSION\n" +
            "---\n" +
            "First name : " + newParams.requestor.firstName + "\n" +
            "Last name : " + newParams.requestor.lastName + "\n" +
            "Email : " + newParams.requestor.email + "\n" +
            "Affiliation : " + newParams.requestor.affiliation + "\n" +
            /* (
                newParams.membraneCharacterizationDetails
                    ?
                    (
                        "---\n" +
                        " Membrane characterization details :\n" +
                        newParams.membraneCharacterizationDetails
                    )
                    :
                    ""
            ) + "\n" + */
            "---\n" +
            "Additional comments :\n" +
            newParams.comment ? newParams.comment : "none"

    console.log("Will return", newParams)

    return newParams
}

const SubmitAMembranePage = () => {

    const [displayOtherSolutesDetails, setDisplayOtherSolutesDetails] = React.useState(false)
    const [displayChemistryOther, setDisplayChemistryOther] = React.useState(false)
    const [displayStructureOther, setDisplayStructureOther] = React.useState(false)
    const [displayModificationOther, setDisplayModificationOther] = React.useState(false)
    const [allReportReferencesForSelectField, setAllReportReferencesForSelectField] = React.useState([])
    const [displaySubmissionStatus, setDisplaySubmissionStatus] = React.useState(false)
    const { membranesSchema, reportsSchema, reports, postMembraneToOMD, postMembraneStatus, queryOMD } = useOMD()
    
    const NOTAVAILABLE = "Not available"

    membranesSchema && (membranesSchema._resourceName = 'membrane')
    reportsSchema && (reportsSchema._resourceName = 'report')

    const getSelectFieldValidations = (schema, data, dataFilter) => {
        const values = schema[data].item ? schema[data].item.validations.enum : schema[data].validations.enum
        return values
            .map(value => ({ value: value, label: value }))
            .sort((a, b) => (a.label > b.label))
            .filter(dataFilter ? dataFilter : (a) => true)
    }

    React.useEffect(
        () => {
            setAllReportReferencesForSelectField(
                () => {
                    if (reports) {
                        return reports
                            .map(
                                ({ _id, reference, ...rest }) => ({ value: _id, label: reference, ...rest })
                            )
                            .sort((a, b) => (a.label > b.label))
                    } else return []
                }
            )
        },
        [reports]
    )

    const Label = ({ schema, data, type, html, prefix, postfix, ...rest }) => {
        if (schema) {
            let label = schema[data].labels.short // Default
            if (html && schema[data].labels[`${type}Html`]) {
                // If html is required, we first verify that the key exists 
                label = schema[data].labels[`${type}Html`]
            }
            // In every other case we return the non html version
            else label = schema[data].labels[type]
            return (
                label ?
                    <span
                        dangerouslySetInnerHTML={{ __html: (prefix ? prefix : '') + label + (postfix ? postfix : '') }}
                        {...rest}
                    />
                    :
                    ''
            )
        } else return ''
    }

    const getInputLabel = (schema, data) => (
        <>
            <Label schema={schema} data={data} type='short' html />
            <Label schema={schema} data={data} type='unit' html className="is-size-7" prefix=' ( ' postfix=' ) ' />
        </>
    )

    const getInputLabelSubtitle = (schema, data) => (
        <Label schema={schema} data={data} type='long' html />
    )

    const SelectField = ({ schema, data, id, sectionId, placeholder, label, labelSubtitle, options, required, dataFilter, ...rest }) => {

        return (
            <ReactSelectField
                label={label ? label : getInputLabel(schema, data)}
                labelSubtitle={labelSubtitle ? labelSubtitle : getInputLabelSubtitle(schema, data)}
                options={options ? options : getSelectFieldValidations(schema, data, dataFilter)}
                id={id ? id : `${schema._resourceName}.${data}`}
                sectionId={sectionId}
                placeholder={placeholder && placeholder}
                multiple={schema && schema[data] && schema[data].item}
                {...(schema && schema[data] && schema[data].validations)}
                required={(required || required === false) ? required : schema[data].validations && (schema[data].validations.required || schema[data].validations.minLength)}
                {...rest}
            />
        )
    }

    const InputField = ({ schema, data, id, sectionId, placeholder, label, labelSubtitle, type, required, ...rest }) => {

        const inputType = type ? type : (schema[data].type && schema[data].type.toLowerCase())

        return (
            <StandardInputField
                label={label ? label : getInputLabel(schema, data)}
                labelSubtitle={labelSubtitle ? labelSubtitle : getInputLabelSubtitle(schema, data)}
                id={id ? id : `${schema._resourceName}.${data}`}
                sectionId={sectionId}
                placeholder={placeholder ? placeholder : label}
                type={inputType}
                step={(inputType && inputType === 'number') ? 'any' : undefined}
                {...(schema && schema[data] && schema[data].validations)}
                required={(required || required === false) ? required : schema && schema[data] && schema[data].validations && schema[data].validations.required}
                {...rest}
            />
        )
    }

    const TextArea = ({ schema, data, id, sectionId, placeholder, label, labelSubtitle, type, required, ...rest }) => {

        return (
            <StandardTextArea
                label={label ? label : getInputLabel(schema, data)}
                labelSubtitle={labelSubtitle ? labelSubtitle : getInputLabelSubtitle(schema, data)}
                id={id ? id : `${schema._resourceName}.${data}`}
                sectionId={sectionId}
                placeholder={placeholder ? placeholder : label}
                type={type ? type : (schema && schema[data] && schema[data].type && schema[data].type.toLowerCase())}
                {...(schema && schema[data] && schema[data].validations)}
                required={(required || required === false) ? required : schema && schema[data] && schema[data].validations && schema[data].validations.required}
                {...rest}
            />
        )
    }


    const FormSubmitMessage = () => {

        return (
            <>
                {(postMembraneStatus && displaySubmissionStatus)
                    ?
                    <Message
                        is={
                            postMembraneStatus.status === 'success'
                                ?
                                'success'
                                :
                                postMembraneStatus.status === 'error'
                                    ?
                                    'danger'
                                    :
                                    'primary'
                        }
                        title='Status of your form submission'
                        message={
                            postMembraneStatus.status === 'success'
                                ?
                                <>
                                    <div className='is-size-6 has-text-weight-bold mb-3'>Thank you for submitting your membrane to the OMD. It will be published to the database after review by the OMD team</div>
                                    <PostSuccessActions
                                        postMembraneStatus={postMembraneStatus}
                                        setDisplaySubmissionStatus={setDisplaySubmissionStatus}
                                        queryOMD={queryOMD}
                                    />
                                </>
                                :
                                postMembraneStatus.status === 'error'
                                    ?
                                    <>
                                        There was an error while sending the form : <br />
                                        CODE {postMembraneStatus?.response?.data?.code} : {postMembraneStatus?.response?.data?.message}
                                    </>
                                    :
                                    'We are sending your submission...'
                        }
                    />
                    :
                    undefined
                }
            </>
        )
    }

    const ReportInputObserver = () => (
        <FormStateConsumer>
            {
                ({ state: { inputs } }) => (
                    inputs.report && inputs.report.value &&
                    <div className="table-container">
                        <h3 className="title is-6 my-3">Current selected report from the OMD database</h3>
                        <table className="table is-size-7 is-fullwidth">
                            {
                                allReportReferencesForSelectField
                                    .filter(report => report.value === inputs['report'].value.value)
                                    .map(
                                        report => (
                                            <tbody key={report.value}>
                                                {
                                                    report && report.label &&
                                                    <tr>
                                                        <td className="has-text-weight-bold">Reference</td>
                                                        <td>{report.label}</td>
                                                    </tr>
                                                }
                                                {
                                                    report && report.author && report.author.lastName &&
                                                    <tr>
                                                        <td className="has-text-weight-bold">Author</td>
                                                        <td>{report.author.lastName}</td>
                                                    </tr>
                                                }
                                                {
                                                    report && report.title &&
                                                    <tr>
                                                        <td className="has-text-weight-bold">Title</td>
                                                        <td>{report.title}</td>
                                                    </tr>
                                                }
                                                {
                                                    report && report.link &&
                                                    <tr>
                                                        <td className="has-text-weight-bold">Publication link</td>
                                                        <td><a href={report.link} target='_blank' rel='noreferrer'>{report.link}</a></td>
                                                    </tr>
                                                }
                                                {
                                                    report && report.publicationDate &&
                                                    <tr>
                                                        <td className="has-text-weight-bold">Publication date</td>
                                                        <td>{new Date(report.publicationDate).getUTCFullYear()}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        )
                                    )
                            }
                        </table>
                    </div>
                )
            }
        </FormStateConsumer >
    )

    return (
        <Layout>
            <Seo title='Submit a membrane to the Open Membrane Database' />
            <Form>
                <div className="section">
                    <div className="container">
                        <h1 className="title is-4">Submit a new membrane for the Open Membrane Database</h1>
                    </div>
                </div>
                <div className="section pt-0 mb-6">
                    <FormSubmitMessage />
                    <div className="container mb-6">
                        <FormSection
                            id='selectReport'
                            isVisibleDefault={true}
                            actions={[
                                {
                                    label: "Save selected report and continue",
                                    className: "is-primary",
                                    nextId: 'membrane',
                                    additionalActions: [
                                        { type: 'toggleSectionIsDeactivated', payload: { id: 'newReport' } }
                                    ]
                                },
                                {
                                    label:
                                        <>
                                            <span className="icon">
                                                <FontAwesomeIcon icon={faPlus} />
                                            </span>
                                            <span>
                                                Add a membrane from a new report
                                            </span>
                                        </>,
                                    validateInputs: false,
                                    className: 'is-text',
                                    nextId: 'newReport',
                                    additionalActions: [
                                        { type: 'toggleSectionIsDeactivated', payload: { id: 'selectReport' } },
                                        { type: 'updateInputValue', payload: { id: 'report', value: NOTAVAILABLE } }
                                    ]
                                },
                            ]}
                            label="Report"
                            labelSubtitle="Does your membrane belong to a report already listed on the OMD ?"
                            dumpStateAdditionalComponents={<ReportInputObserver />}
                        >
                            <SelectField
                                label="Report Reference from our database"
                                labelSubtitle="Search for a pre-existing report by DOI, product datasheet reference, or patent number"
                                options={allReportReferencesForSelectField}
                                id='report'
                                sectionId='selectReport'
                                placeholder="Please choose a report"
                                required
                            />
                            <ReportInputObserver />
                            <p className="is-size-7">
                                <FontAwesomeIcon icon={faSearch} /> Want to search by author name, year, title ? Use the global search of the data table on the <Link target='_blank' to='/reverse-osmosis-database#table' rel='noreferrer'><u>RO Database page</u></Link>
                            </p>
                        </FormSection>
                        <FormSection
                            id='newReport'
                            actions={[
                                {
                                    label: "Save and continue",
                                    nextId: 'membrane'
                                },
                            ]}
                            label="Report"
                            labelSubtitle='Please fill-in all details for the report'
                        >
                            <Message
                                is='primary'
                                message={
                                    <>
                                        <div className=" is-size-6 mb-3">
                                            Please make sure you double-checked the report you want to fill-in isn't already in our database &nbsp;
                                        </div>
                                        <FormButton
                                            label={
                                                <>
                                                    <span className="icon">
                                                        <FontAwesomeIcon icon={faSearch} />
                                                    </span>
                                                    <span>
                                                        check again
                                                    </span>
                                                </>
                                            }
                                            actions={[
                                                { type: 'toggleSectionIsDeactivated', payload: { id: 'newReport' } },
                                                { type: 'toggleSectionIsVisible', payload: { id: 'selectReport' } }
                                            ]}
                                            className="is-primary is-small"
                                        />
                                    </>}
                            />
                            <hr />
                            <Columns is={6}>
                                <InputField
                                    labelSubtitle={"DOI, product datasheet reference or patent number"}
                                    schema={reportsSchema}
                                    data='reference'
                                    sectionId='newReport'
                                    info={{ text: 'See examples provided' }}
                                    defaultFocus
                                />
                                <Message
                                    title="Reference examples for each type of document"
                                    is='success'
                                    message={
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Scientific reports
                                                    </td>
                                                    <td>
                                                        10.1066/C8TA01162B
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Patents
                                                    </td>
                                                    <td>
                                                        U.S. Patent No. 4287910
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Product Data Sheets
                                                    </td>
                                                    <td>
                                                        URL
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }
                                />
                            </Columns>
                            <Columns is={6}>
                                <InputField
                                    schema={reportsSchema}
                                    data='author.lastName'
                                    sectionId='newReport'
                                    labelSubtitle='Last name of first author or manufacturer name for commercial membranes'
                                    info={{ text: 'Enter the company name for commercial membranes' }}
                                />
                                <Columns is={6}>
                                    <InputField
                                        schema={reportsSchema}
                                        data='publicationDate'
                                        sectionId='newReport'
                                        type='number'
                                        step='1'
                                        min='1900'
                                        max={(new Date().getFullYear() + 1)}
                                    />
                                    <SelectField
                                        schema={reportsSchema}
                                        data='type'
                                        sectionId='newReport'
                                        label='Type' // Should be sent by the B.O.
                                        labelSubtitle='Report type' // Should be sent by the B.O.
                                        placeholder={'Choose...'}
                                    />
                                </Columns>
                            </Columns>
                            <Columns is={12}>
                                <InputField
                                    schema={reportsSchema}
                                    data='title'
                                    sectionId='newReport'
                                    labelSubtitle='Report title or membrane name for commercial membranes'
                                />
                            </Columns>

                        </FormSection>
                        <FormSection
                            id="membrane"
                            label="Membrane information"
                            actions={[
                                {
                                    label: "Save and continue",
                                    nextId: 'testInformations'
                                },
                            ]}
                        >
                            <Columns is={6}>
                                <InputField
                                    schema={membranesSchema}
                                    data='name'
                                    sectionId='membrane'
                                    labelSubtitle='Write a unique name for the membrane that it can be identified in your reference document.'
                                    info={{ text: 'Write a unique name for the membrane \nthat it can be identified in your reference document.' }}
                                    defaultFocus
                                />
                            </Columns>
                            <Columns is={6}>
                                <SelectField
                                    schema={membranesSchema}
                                    data='chemistry'
                                    sectionId='membrane'
                                    placeholder={'Choose...'}
                                    additionalOnChange={(e) => {
                                        if (e.map(value => value.value).includes('Other')) {
                                            setDisplayChemistryOther(true)
                                        } else {
                                            setDisplayChemistryOther(false)
                                        }
                                    }}
                                    additionalActions={
                                        displayChemistryOther
                                            ?
                                            [
                                                {
                                                    type: 'deregisterInput',
                                                    payload: {
                                                        id: 'membrane.chemistryOther',
                                                        value: undefined
                                                    }
                                                },
                                            ]
                                            :
                                            []
                                    }
                                />
                                {
                                    displayChemistryOther
                                        ?
                                        <InputField
                                            schema={membranesSchema}
                                            data='chemistryOther'
                                            sectionId='membrane'
                                            info={{ text: 'You selected \'Other\' as value.\nPlease provide additional information' }}
                                        />
                                        :
                                        undefined
                                }
                            </Columns>
                            <Columns is={6}>
                                <SelectField
                                    schema={membranesSchema}
                                    data='modification'
                                    sectionId='membrane'
                                    info={{ text: 'Definitions of modification techniques detailed in our publication,\n click to see our publication (coming soon)', link: '#' }}
                                    additionalOnChange={(e) => {
                                        if (e.map(value => value.value).includes('Other')) {
                                            setDisplayModificationOther(true)
                                        } else {
                                            setDisplayModificationOther(false)
                                        }
                                    }}
                                    additionalActions={
                                        displayModificationOther
                                            ?
                                            [
                                                {
                                                    type: 'deregisterInput',
                                                    payload: {
                                                        id: 'membrane.modificationOther',
                                                        value: undefined
                                                    }
                                                },
                                            ]
                                            :
                                            []
                                    }
                                />
                                {
                                    displayModificationOther
                                        ?
                                        <InputField
                                            schema={membranesSchema}
                                            data='modificationOther'
                                            sectionId='membrane'
                                            info={{ text: 'You selected \'Other\' as value.\nPlease provide additional information' }}
                                        />
                                        :
                                        undefined
                                }
                            </Columns>
                            <Columns is={6}>
                                <SelectField
                                    schema={membranesSchema}
                                    data='structure'
                                    sectionId='membrane'
                                    multiple={false}
                                    placeholder={'Choose...'}
                                    additionalOnChange={(e) => {
                                        if (e.value === 'Other') {
                                            setDisplayStructureOther(true)
                                        } else {
                                            setDisplayStructureOther(false)
                                        }
                                    }}
                                    additionalActions={
                                        displayStructureOther
                                            ?
                                            [
                                                {
                                                    type: 'deregisterInput',
                                                    payload: {
                                                        id: 'membrane.structureOther',
                                                        value: undefined
                                                    }
                                                },
                                            ]
                                            :
                                            []
                                    }
                                />
                                {
                                    displayStructureOther
                                        ?
                                        <InputField
                                            schema={membranesSchema}
                                            data='structureOther'
                                            sectionId='membrane'
                                            info={{ text: 'You selected \'Other\' as value.\nPlease provide additional information' }}
                                        />
                                        :
                                        undefined
                                }
                            </Columns>
                        </FormSection>
                        <FormSection
                            id='testInformations'
                            label="Test information"
                            actions={[
                                {
                                    label: "Save and continue",
                                    nextId: 'testConditions'
                                },
                            ]}
                        >
                            <Columns is={3}>
                                <InputField
                                    schema={membranesSchema}
                                    data='data.a'
                                    sectionId='testInformations'
                                    info={{ text: 'Click here to use our calculator', link: '/calculators/membrane-performance-calculator' }}
                                    defaultFocus
                                    additionalActions={[
                                        {
                                            type: 'updateInputValue',
                                            payload: {
                                                id: 'membrane.data.ab',
                                                value: {
                                                    fn: (a, b) => (a / b),
                                                    values: ['membrane.data.a', 'membrane.data.b']
                                                }
                                            }
                                        },
                                    ]}
                                />
                                <InputField
                                    schema={membranesSchema}
                                    data='data.b'
                                    sectionId='testInformations'
                                    info={{ text: 'Click here to use our calculator', link: '/calculators/membrane-performance-calculator' }}
                                    additionalActions={[
                                        {
                                            type: 'updateInputValue',
                                            payload: {
                                                id: 'membrane.data.ab',
                                                value: {
                                                    fn: (a, b) => (a / b),
                                                    values: ['membrane.data.a', 'membrane.data.b']
                                                }
                                            }
                                        },
                                    ]}
                                />
                                <InputField
                                    schema={membranesSchema}
                                    data='data.ab'
                                    sectionId='testInformations'
                                    onChange={() => undefined}
                                    className="is-static"
                                    readOnly
                                />
                                <InputField
                                    schema={membranesSchema}
                                    data='data.rreal'
                                    sectionId='testInformations'
                                    info={{ text: 'Click here to use our calculator', link: '/calculators/membrane-performance-calculator' }}
                                />
                                <InputField
                                    schema={membranesSchema}
                                    data='data.robs'
                                    sectionId='testInformations'
                                    info={{ text: 'Click here to use our calculator', link: '/calculators/membrane-performance-calculator' }}
                                    />
                                <InputField
                                    schema={membranesSchema}
                                    data='data.pw'
                                    sectionId='testInformations'
                                    info={{ text: 'Click here to use our calculator', link: '/calculators/membrane-performance-calculator' }}
                                    />
                                <InputField
                                    schema={membranesSchema}
                                    data='data.ps'
                                    sectionId='testInformations'
                                    info={{ text: 'Click here to use our calculator', link: '/calculators/membrane-performance-calculator' }}
                                />
                            </Columns>
                        </FormSection>
                        <FormSection
                            id='testConditions'
                            label="Test conditions"
                            actions={[
                                {
                                    label: "Save and continue",
                                    nextId: 'membraneCharacterization'
                                },
                            ]}
                        >
                            <Columns is={4}>
                                <InputField
                                    schema={membranesSchema}
                                    data='data.hydraulicP'
                                    sectionId='testConditions'
                                    defaultFocus
                                />
                                <InputField
                                    schema={membranesSchema}
                                    data='data.naClConcM'
                                    sectionId='testConditions'
                                />
                                <InputField
                                    schema={membranesSchema}
                                    data='data.osmoticP'
                                    sectionId='testConditions'
                                    info={{text: 'Click here to use our calculator', link: '/calculators/osmotic-pressure-calculator'}}
                                    />
                                <InputField
                                    schema={membranesSchema}
                                    data='data.cp'
                                    sectionId='testConditions'
                                    min={1} // Asked by the OMD team, Back-office default is 0
                                    required // TODO, should be enforced by the backend
                                    info={{text: 'Click here to use our calculator', link: '/calculators/concentration-polarization-calculator'}}
                                />
                                <InputField
                                    schema={membranesSchema}
                                    data='data.ph'
                                    sectionId='testConditions'
                                />
                                <InputField
                                    schema={membranesSchema}
                                    data='data.couponSize'
                                    sectionId='testConditions'
                                />
                            </Columns>
                            <Columns is={6}>
                                <SelectField
                                    schema={membranesSchema}
                                    data='data.filtrationMode'
                                    sectionId='testConditions'
                                    placeholder={'Choose...'}
                                    dataFilter={(a) => a.value !== "Not available"}
                                />
                            </Columns>
                            <Columns is={6}>
                                <SelectField
                                    schema={membranesSchema}
                                    data={'data.otherSolutes'}
                                    sectionId='testConditions'
                                    labelSubtitle='Were solutes other than NaCl present during filtration?'
                                    options={[
                                        { value: true, label: 'yes' },
                                        { value: false, label: 'no' },
                                    ]}
                                    placeholder={'Choose...'}
                                    additionalOnChange={(e) => {
                                        if (e.value + '' === 'true') {
                                            setDisplayOtherSolutesDetails(true)
                                        } else {
                                            setDisplayOtherSolutesDetails(false)
                                        }
                                    }}
                                    additionalActions={
                                        displayOtherSolutesDetails
                                            ?
                                            [
                                                {
                                                    type: 'deregisterInput',
                                                    payload: {
                                                        id: 'membrane.data.otherSolutesDetails',
                                                        value: undefined
                                                    }
                                                },
                                            ]
                                            :
                                            []
                                    }
                                />
                                {
                                    displayOtherSolutesDetails
                                        ?
                                        <InputField
                                            schema={membranesSchema}
                                            data='data.otherSolutesDetails'
                                            sectionId='testConditions'
                                            info={{ text: 'You selected \'Other\' as value.\nPlease provide additional information' }}
                                        />
                                        :
                                        undefined
                                }
                            </Columns>
                        </FormSection>
                        <FormSection
                            id='membraneCharacterization'
                            label="Membrane characterization"
                            actions={[
                                {
                                    label: "Save and continue",
                                    nextId: 'review'
                                },
                            ]}
                        >
                            <Columns is={6}>
                                <InputField
                                    schema={membranesSchema}
                                    data='data.thickness'
                                    sectionId='membraneCharacterization'
                                    defaultFocus
                                />
                                <InputField
                                    schema={membranesSchema}
                                    data='data.roughness'
                                    sectionId='membraneCharacterization'
                                />
                                <InputField
                                    schema={membranesSchema}
                                    data='data.ca'
                                    sectionId='membraneCharacterization'
                                />
                            </Columns>
                            <Columns is={6}>
                                <SelectField
                                    schema={membranesSchema}
                                    data='data.characterization'
                                    sectionId='membraneCharacterization'
                                />
                                {/* <InputField
                                    id='membraneCharacterizationDetails'
                                    sectionId='membraneCharacterization'
                                    type='text'
                                    label="Membrane characterization details"
                                    labelSubtitle="Provide additional information on your membrane characterization data"
                                    placeholder=' '
                                /> */}
                            </Columns>
                        </FormSection>
                        <FormSection
                            id='review'
                            label="Contact information and additional details"
                            actions={[
                                {
                                    label: "Submit membrane to the OMD Team",
                                    type: 'submit',
                                    additionalActions: [
                                        {
                                            type: 'sendData', payload: {
                                                sendData: (params) => {
                                                    setDisplaySubmissionStatus(true)
                                                    postMembraneToOMD(cleanUpFormParamsBeforeSend(params))
                                                }
                                            }
                                        },
                                    ],
                                    disabled: (
                                        displaySubmissionStatus &&
                                        postMembraneStatus &&
                                        postMembraneStatus.status &&
                                        (postMembraneStatus.status === 'sending' || postMembraneStatus.status === 'success')
                                    ) &&
                                        (
                                            postMembraneStatus.status === 'sending'
                                                ? 'Submitting...'
                                                : 'Your membrane has been submitted'
                                        )
                                },
                            ]}
                        >
                            <Columns is={4}>
                                <InputField
                                    label='First name'
                                    id='requestor.firstName'
                                    sectionId='review'
                                    type='text'
                                    defaultFocus
                                />
                                <InputField
                                    label='Last name'
                                    id='requestor.lastName'
                                    sectionId='review'
                                    type='text'
                                    required
                                />
                            </Columns>
                            <Columns is={4}>
                                <InputField
                                    label='University / company / other'
                                    id='requestor.affiliation'
                                    sectionId='review'
                                    type='text'
                                    required
                                />
                                <InputField
                                    label='E-mail'
                                    id='requestor.email'
                                    sectionId='review'
                                    type='email'
                                    required
                                />
                            </Columns>
                            <Columns is={8}>
                                <TextArea
                                    id='comment'
                                    sectionId='review'
                                    label='Additional comments'
                                    labelSubtitle='Message to the OMD Team'
                                    placeholder='Comments'
                                />
                            </Columns>
                            <input id="agreement" type="checkbox" required />
                            <label htmlFor="agreement"> I have carefully reviewed all the data sent and agree to be contacted if further information is needed <FieldRequired /></label>
                        </FormSection>
                    </div>
                    <FormSubmitMessage />
                </div>
            </Form>
        </Layout >
    )
}

export default SubmitAMembranePage