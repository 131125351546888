import React, { useEffect, useMemo, useReducer, useRef } from 'react'
import { Link } from 'gatsby'
import Select from 'react-select'

let MyContext

let { Provider } = (MyContext = React.createContext())
export const FormStateConsumer = MyContext.Consumer

const reducer = (state, action) => {
    switch (action.type) {
        case 'registerSection': {
            if (action.payload.sectionId) {
                return {
                    ...state,
                    sections: {
                        ...state.sections,
                        [action.payload.sectionId]: {
                            isVisible: action.payload.isVisible ? action.payload.isVisible : false,
                            isActivated: action.payload.isVisible ? action.payload.isVisible : false
                        }
                    }
                }
            }
            else throw new Error("Cant register form section without at least a section ID")
        }
        case 'deregisterSection': {
            if (action.payload.sectionId) {
                const newSections = state.sections
                delete newSections[action.payload.sectionId]
                return {
                    ...state,
                    sections: {
                        ...newSections
                    }
                }
            }
            else {
                console.log("Cant deregister form section : ", action.payload.sectionId)
                return state
            }
        }
        case 'toggleSectionIsHidden': {
            if (action.payload.id) {
                return {
                    ...state,
                    sections: {
                        ...state.sections,
                        [action.payload.id]: {
                            ...state.sections[action.payload.id],
                            isVisible: false
                        }
                    }
                }
            }
            else throw new Error("You have to provide an ID for the section to hide")
        }
        case 'toggleSectionIsVisible': {
            if (action.payload.id) {
                return {
                    ...state,
                    sections: {
                        ...state.sections,
                        [action.payload.id]: {
                            ...state.sections[action.payload.id],
                            isActivated: true,
                            isVisible: true
                        }
                    }
                }
            }
            else throw new Error("You have to provide an ID for the section to show")
        }
        case 'toggleSectionIsDeactivated': {
            if (action.payload.id) {
                return {
                    ...state,
                    sections: {
                        ...state.sections,
                        [action.payload.id]: {
                            ...state.sections[action.payload.id],
                            isActivated: false,
                            isVisible: false
                        }
                    }
                }
            }
            else throw new Error("You have to provide an ID for the section to hide")
        }
        case 'toggleHideAllSections': {
            return {
                ...state,
                sections: {
                    ...(
                        Object.keys(state.sections)
                            .map(sectionId => {
                                state.sections[sectionId].isVisible = false
                                return ({ [sectionId]: state.sections[sectionId] })
                            })
                            .reduce(
                                (acc, curr) => {
                                    acc[Object.keys(curr)[0]] = curr[Object.keys(curr)[0]]
                                    return acc
                                },
                                {}
                            )
                    )
                }
            }
        }
        case 'registerInput': {
            if (action.payload.id) {
                return {
                    ...state,
                    inputs: {
                        ...state.inputs,
                        [action.payload.id]: {
                            ...(action.payload.sectionId && { section: action.payload.sectionId }),
                            ...(action.payload.label && { label: action.payload.label })
                        }
                    }
                }
            }
            else throw new Error("Cant register an input without an ID")
        }
        case 'deregisterInput': {
            if (action.payload.id) {
                const newInputs = state.inputs
                delete newInputs[action.payload.id]
                return {
                    ...state,
                    inputs: {
                        ...newInputs
                    }
                }
            }
            else throw new Error("Cant deregister an input without an ID")
        }
        case 'updateInputValue': {
            if (action.payload.id) {
                // We have a custom function (a, b) provided to compute the value from other input values
                if (typeof action.payload.value === 'object' && action.payload.value.fn) {
                    return {
                        ...state,
                        inputs: {
                            ...state.inputs,
                            [action.payload.id]: {
                                ...state.inputs[action.payload.id],
                                value: action.payload.value.fn(
                                    state.inputs[action.payload.value.values[0]] && state.inputs[action.payload.value.values[0]].value,
                                    state.inputs[action.payload.value.values[1]] && state.inputs[action.payload.value.values[1]].value,
                                )
                            }
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        inputs: {
                            ...state.inputs,
                            [action.payload.id]: {
                                ...state.inputs[action.payload.id],
                                value: action.payload.value
                            }
                        }
                    }

                }
            }
            else throw new Error("Cant update an input value without an ID")
        }
        case 'sendData': {
            if (typeof action.payload.sendData === 'function') {
                action.payload.sendData(state.inputs)
                return state
            }
            else throw new Error("no 'sendData(params)' function into payload")
        }
        default: console.error("Unknow reducer action : ", action.payload)

    }
}

const Form = ({ children }) => {

    const formRef = useRef(null)
    const [state, dispatch] = useReducer(reducer, { sections: {}, inputs: {} })
    const contextValue = useMemo(() => ({ state, dispatch, formRef }), [state, dispatch, formRef])

    return (
        <Provider value={contextValue}>
            <form ref={formRef} onSubmit={() => console.log("submit")}>
                {children}
            </form>
        </Provider>
    )
}

export default Form

export const useFormState = () => React.useContext(MyContext)

export const Message = ({ is, title, message }) => {

    return (
        <article className={["message is-small", is ? `is-${is}` : "is-danger"].join(' ')}>
            {
                title
                    ?
                    <div className="message-header">
                        <p>{title}</p>
                    </div>
                    :
                    undefined
            }
            <div className="message-body">
                {message}
            </div>
        </article>
    )
}


export const InputField = ({ label, placeholder, labelSubtitle, sectionId, id, info, additionalActions, onChange, additionalOnChange, className, defaultFocus, ...rest }) => {

    const { state, dispatch } = useFormState()
    const inputRef = useDefaultFocus(defaultFocus)

    return (
        <AbstractInputField label={label} labelSubtitle={labelSubtitle} sectionId={sectionId} id={id} info={info} {...rest}>
            <div className="control">
                <input
                    ref={inputRef}
                    className={["input", className ? className : undefined].join(' ')}
                    type="text"
                    placeholder={placeholder && placeholder}
                    onChange={
                        onChange ?
                            onChange :
                            (e) => {
                                dispatch({ type: 'updateInputValue', payload: { id: id, value: e.target.value } })
                                if (additionalActions) {
                                    additionalActions.forEach(action =>
                                        dispatch({ type: action.type, payload: { id: action.payload.id, value: action.payload.value } })
                                    )
                                }
                                if (additionalOnChange) {
                                    additionalOnChange(e)
                                }
                            }
                    }
                    value={state.inputs[id] && state.inputs[id].value}
                    {...rest}
                />
            </div>
        </AbstractInputField>
    )
}

export const TextArea = ({ label, placeholder, labelSubtitle, sectionId, id, info, ...rest }) => {

    const { state, dispatch } = useFormState()

    return (
        <AbstractInputField label={label} labelSubtitle={labelSubtitle} sectionId={sectionId} id={id} info={info} {...rest}>
            <div className="control">
                <textarea
                    className="textarea"
                    placeholder={placeholder && placeholder}
                    onChange={e => dispatch({ type: 'updateInputValue', payload: { id: id, value: e.target.value } })}
                    value={state.inputs[id] && state.inputs[id].value}
                    {...rest}
                />
            </div>
        </AbstractInputField>
    )
}

export const FieldRequired = () => <span className="has-text-danger" data-tooltip='This field is required'> *</span>

const AbstractInputField = ({ label, labelSubtitle, children, sectionId, id, info, required }) => {

    const { state, dispatch } = useFormState()
    useEffect(
        () => {
            !state.inputs[id] &&
                dispatch({ type: 'registerInput', payload: { sectionId: sectionId, id: id, label: label } })
        }
        , [sectionId, id, label, dispatch, state.inputs]
    )

    return (
        <div className="field">
            <label className="label" htmlFor={id}>
                {
                    label && label
                }
                {
                    required && <FieldRequired />
                }
                {
                    info && <a href={info.link ? info.link : '#'} target='_blank' rel='noreferrer' className="has-text-link is-pulled-right has-tooltip-left" data-tooltip={info.text}> ⓘ</a>
                }
                {
                    labelSubtitle && <><br /><span className="subtitle is-7">{labelSubtitle}</span></>
                }
            </label>
            <div className="control">
                {children}
            </div>
        </div>
    )
}

const useDefaultFocus = (defaultFocus) => {
    const inputRef = useRef()

    useEffect(
        () => {
            defaultFocus && inputRef.current.focus()
        }
        , [defaultFocus]
    )

    return inputRef
}

export const ReactSelectField = ({ label, options, labelSubtitle, id, placeholder, sectionId, multiple, info, additionalActions, additionalOnChange, onChange, defaultFocus, ...rest }) => {
    const { state, dispatch } = useFormState()
    const inputRef = useDefaultFocus(defaultFocus)

    return (
        <AbstractInputField label={label} labelSubtitle={labelSubtitle} sectionId={sectionId} id={id} info={info} {...rest}>
            <Select
                isMulti={multiple}
                defaultValue={multiple ? [''] : ''}
                ref={inputRef}
                options={options}
                value={(state.inputs[id] && state.inputs[id].value)}
                placeholder='Select or search...'
                onChange={
                    onChange
                        ?
                        onChange
                        :
                        e => {
                            dispatch({
                                type: 'updateInputValue',
                                payload: {
                                    id: id,
                                    value: e
                                }
                            })
                            if (additionalOnChange) {
                                additionalOnChange(e)
                            }
                            if (additionalActions) {
                                additionalActions.forEach(action =>
                                    dispatch({ type: action.type, payload: { id: action.payload.id, value: action.payload.value } })
                                )
                            }
                        }}
                {...rest}
            />
        </AbstractInputField>
    )
}

/* OBSOLETE SelectField !! */
export const SelectField = ({ label, options, labelSubtitle, id, placeholder, sectionId, multiple, info, additionalActions, additionalOnChange, onChange, defaultFocus, ...rest }) => {
    const { state, dispatch } = useFormState()
    const inputRef = useDefaultFocus(defaultFocus)

    const infoExtended =
        (multiple && info)
            ?
            { ...info, text: '\n Use ⌘ key (Mac) or CTRL (Windows) for multi-select' + info.text }
            :
            multiple
                ?
                { text: 'Use ⌘ key (Mac) or CTRL (Windows) for multi-select' }
                :
                undefined

    return (
        <AbstractInputField label={label} labelSubtitle={labelSubtitle} sectionId={sectionId} id={id} info={infoExtended} {...rest}>
            <div className={["select is-fullwidth", multiple ? "is-multiple" : undefined].join(' ')}>
                <select
                    ref={inputRef}
                    onChange={
                        onChange
                            ?
                            onChange
                            :
                            e => {
                                dispatch({
                                    type: 'updateInputValue',
                                    payload: {
                                        id: id,
                                        value:
                                            multiple
                                                ?
                                                Array.from(e.target.selectedOptions, option => option.value)
                                                :
                                                e.target.value
                                    }
                                })
                                if (additionalOnChange) {
                                    additionalOnChange(e)
                                }
                                if (additionalActions) {
                                    additionalActions.forEach(action =>
                                        dispatch({ type: action.type, payload: { id: action.payload.id, value: action.payload.value } })
                                    )
                                }
                            }}
                    defaultValue={multiple ? [''] : ''}
                    value={
                        (state.inputs[id] && state.inputs[id].value)
                    }
                    multiple={multiple}
                    {...rest}
                >
                    {
                        placeholder && <option value="" disabled hidden>{placeholder}</option>
                    }
                    {
                        options && options.length &&
                        options.map(({ value, label }) => <option key={value} value={value}>{label}</option>)
                    }
                </select>
                {multiple ? <span className="pt-1 pl-1 is-size-7"><span className="is-link is-size-6">↳</span> Use <b>⌘</b> key (Mac) or CTRL (Windows) for multi-select</span> : undefined}
            </div>

        </AbstractInputField>
    )
}

export const Columns = ({ children, is }) => (
    <div className="columns is-multiline">
        {
            React.Children.map(children, ((child, index) => (
                <div className={`column is-${is}`} key={index}>
                    {child}
                </div>
            )))
        }
    </div>
)

export const FormButton = ({ label, className, actions, onClick, ...rest }) => {

    const { dispatch } = useFormState()

    return (
        <button
            className={["button", className && className].join(' ')}
            onClick={
                () => (
                    onClick
                        ?
                        onClick()
                        :
                        (actions && actions.length && actions.length > 0)
                            ?
                            actions.map(action => dispatch(action))
                            :
                            undefined
                )
            }
            {...rest}
        >
            {label}
        </button>
    )
}

const DumpState = ({ state, sectionId }) => {
    const filtered = Object.keys(state.inputs)
        .filter(id => state.inputs[id].section === sectionId)
        .map(id => (
            {
                [id]: state.inputs[id]
            }
        ))

    return (
        <table className="table is-size-7 is-hoverable">
            <tbody>
                {
                    filtered && filtered.length && filtered.length > 0 && filtered.map(
                        (input) => {
                            const key = Object.keys(input)[0]
                            return (
                                <tr key={key}>
                                    <td>
                                        <span className="has-text-weight-bold">
                                            {
                                                input[key].label
                                            }
                                        </span>
                                    </td>
                                    <td>
                                        <span style={{ textTransform: 'capitalize' }}>
                                            {
                                                input[key].value
                                                    ?
                                                    Array.isArray(input[key].value)
                                                        ?
                                                        input[key].value.map(value => (value.value !== undefined) ? value.value : value).join(' / ')
                                                        :
                                                        (input[key].value.value !== undefined)
                                                            ?
                                                            input[key].value.label
                                                            :
                                                            input[key].value

                                                    :
                                                    'Not provided'
                                            }
                                        </span>
                                    </td>
                                </tr>
                            )
                        }
                    )
                }
            </tbody>
        </table>
    )
}

export const FormSection = ({ children, id, isVisibleDefault, label, labelSubtitle, actions, dumpStateAdditionalComponents }) => {
    const { state, dispatch, formRef } = useFormState()
    const isActivated = state.sections[id] && state.sections[id].isActivated
    const isVisible = state.sections[id] && state.sections[id].isVisible
    const formSectionRef = useRef()

    useEffect(
        () => {
            dispatch({ type: 'registerSection', payload: { sectionId: id, isVisible: isVisibleDefault } })
        }, 
        [dispatch, id, isVisibleDefault]
        )

    useEffect(
        () => {
            if (isVisible && isActivated) {
                window.scrollTo(
                    {
                        top: window.scrollY + formSectionRef?.current?.getBoundingClientRect().top - 10,
                        behavior: 'smooth'
                    }
                )
            }
        },
        [isActivated, isVisible]
    )

    return (
        state.sections[id] && state.sections[id].isActivated
            ?
            <div
                className={
                    [
                        "card",
                        isActivated && !isVisible && "is-shadowless"
                    ]
                        .join(' ')
                }
                ref={formSectionRef}
            >
                <div className={["card-header", (isActivated && !isVisible) && 'is-shadowless'].join(' ')}>
                    <div className="card-header-title">
                        <div>
                            {
                                label && <h2 className="title is-4">
                                    {label}
                                    {
                                        !isVisible &&
                                        <FormButton
                                            label="Edit"
                                            className="is-small ml-3"
                                            onClick={() => {
                                                dispatch({ type: 'toggleHideAllSections', payload: {} })
                                                dispatch({ type: 'toggleSectionIsVisible', payload: { id: id } })
                                            }}
                                            type='button'
                                        />
                                    }
                                </h2>
                            }
                            {
                                isVisible && labelSubtitle &&
                                <p className="subtitle is-6">{labelSubtitle}</p>
                            }
                        </div>
                    </div>
                </div>
                <div className="card-content">
                    {
                        !isVisible
                            ?
                            <>
                                <DumpState state={state} sectionId={id} />
                                {dumpStateAdditionalComponents ? dumpStateAdditionalComponents : ''}
                            </>
                            :
                            children
                    }
                </div>
                <div className="card-footer">
                    {
                        state.sections[id] && state.sections[id].isVisible &&
                        actions && actions.length && actions.length > 0 &&
                        actions.map(
                            ({ label, className, nextId, additionalActions, validateInputs = true, disabled, ...rest }) => (
                                <div className="card-footer-item" key={label}>
                                    <FormButton
                                        label={disabled ? disabled : label}
                                        className={className}
                                        onClick={
                                            () => {
                                                if (
                                                    (validateInputs && formRef.current.checkValidity())
                                                    || !validateInputs
                                                ) {
                                                    nextId && dispatch({ type: 'toggleSectionIsVisible', payload: { id: nextId } })
                                                    dispatch({ type: 'toggleSectionIsHidden', payload: { id: id } })
                                                    additionalActions && additionalActions.length && additionalActions.length > 0 &&
                                                        additionalActions.map(action => dispatch(action))
                                                }
                                            }
                                        }
                                        disabled={disabled ? true : undefined}
                                        {
                                        ...rest
                                        }
                                    />
                                </div>
                            ))
                    }
                </div>
                {
                    state.sections[id] && state.sections[id].isVisible &&
                    <progress
                        className="progress is-small is-primary is-radiusless"
                        value={Object.keys(state.sections).indexOf(id) + 1}
                        max={Object.keys(state.sections).length}
                    />
                }
            </div>
            : ""
    )
}

export const PostSuccessActions = ({ postMembraneStatus, setDisplaySubmissionStatus, queryOMD }) => {

    const { state, dispatch } = useFormState()
    const [templateMode, setTemplateMode] = React.useState(false)
    const [lastReportIdSubmitted, setLastReportIdSubmitted] = React.useState(undefined)

    React.useEffect(
        () => {
            if (postMembraneStatus && postMembraneStatus.status === 'success') {
                setLastReportIdSubmitted(postMembraneStatus.data.result.report)
            }
        },
        [postMembraneStatus]
    )
    const onSubmit = async () => {

        // We remove the Form Submission Status banner
        setDisplaySubmissionStatus(false)

        // We hide all the sections (they are still activated)
        dispatch({ type: 'toggleHideAllSections', payload: {} })

        if (!templateMode) {
            // deregister all inputs and sections
            Object.keys(state.inputs).forEach(
                // We deregister (delete) all inputs
                inputId => dispatch({ type: 'deregisterInput', payload: { id: inputId } })
            )
            Object.keys(state.sections).forEach(
                // We deregister (delete) all sections
                sectionId => dispatch({ type: 'deregisterSection', payload: { sectionId: sectionId } })
            )
        } else {
            // We still have to deregister "newReport" section (if existed), because the report has been sent and is now available 
            // From the selectReport section and will be pre-selected
            Object.keys(state.inputs).forEach(
                inputId => {
                    if (state.inputs[inputId].section === 'newReport') {
                        dispatch({ type: 'deregisterInput', payload: { id: inputId } })
                    }
                }
            )
            dispatch({ type: 'toggleSectionIsHidden', payload: { id: 'newReport' } })
            dispatch({ type: 'toggleSectionIsDeactivated', payload: { id: 'newReport' } })

            //We deregister "Membrane Name" input, it will always be new
            dispatch({ type: 'deregisterInput', payload: { id: 'membrane.name' } })
        }

        const data = await queryOMD('/reports', { _id: lastReportIdSubmitted })
        const report = data.result[0]
        report.value = report._id
        report.label = report.reference
        
        dispatch({ type: 'registerSection', payload: { sectionId: 'selectReport', isVisible: true } })
        dispatch({ type: 'updateInputValue', payload: { id: 'report', value: report } })
    }

    return (
        <div className='box is-size-6'>
            <p className='mb-3'><b>Would you like to upload another membrane?</b></p>
            <form>
                <div className="field">
                    <div className="control">
                        <label className="radio">
                            <input type='radio'
                                id='templateModeFalse'
                                name='templateMode'
                                className='mr-2'
                                checked={templateMode === false}
                                onChange={() => setTemplateMode(false)}
                            />
                            Yes, from a new report (start over with <b>blank template</b>)
                        </label>
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <label className="radio">
                            <input type='radio'
                                id='templateModeTrue'
                                name='templateMode'
                                className='mr-2'
                                checked={templateMode === true}
                                onChange={() => setTemplateMode(true)}
                                defaultChecked={true}
                            />
                            Yes, from the same report (<b>duplicate all fields</b> from the last submission to save time)
                        </label>
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <input type='button' onClick={onSubmit} className='button is-primary mr-3' value='Submit a new membrane' />
                        <Link to='/' className="button is-primary is-outlined">No, exit to homepage</Link>
                    </div>
                </div>
            </form>
        </div>
    )
}